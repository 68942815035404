import { config } from "process";

const configDev = {
    SmartContract_ADDRESS:'0xBF704c92A0341015d44ef1C0569638b55a70Afa7',
    SmartContract_CHANNEL:5,
    SmartContract_ChannelName:"Goerli",

    website_link:"https://test-nft.mynext.id",
    etherscan_link:"https://goerli.etherscan.io/tx/",
    chain_title:"Goerli",
    chain_id:"0x5",
};

const configProd = {
    SmartContract_ADDRESS:'0x83e9f98fe8f67645eb372bc92a6a8f99bf9be690',
    SmartContract_CHANNEL:1,
    SmartContract_ChannelName:"Ethereum",

    website_link:"https://nft.mynext.id",
    etherscan_link:"https://etherscan.io/tx/",
    chain_title:"Ethereum",
    chain_id:"0x1",
};

const cfg = {
    "https://nft.mimotho.com": configProd,
    "http://localhost:3000": configDev
}

const url = window.location.origin;

export default cfg[url] ? cfg[url] : configProd;