import Web3 from "web3";
import {useState} from "react";

export interface TxHashObject {
    blockHash: string | null | undefined;
    blockNumber: number | null | undefined;
    status: boolean | null | undefined;
    transactionHash: string | null | undefined;
}

export interface AuthorshipStatementObj {
    address: string;
    assetId: string;
    secret: string;
}

export interface Web3ClientsInterface {
    metamask: boolean,
}

export interface Web3InfoInterface {
    success: boolean,
    clients: Web3ClientsInterface,
}

export const getWeb3 = (): any => {
    let out: Web3InfoInterface = {
        clients: {
            metamask: false,
        },
        success: false,
    };
    if (window.ethereum) {
        out.success = true;
        if(window.ethereum.isMetaMask) {
            out.clients.metamask = true;
        }
    }
    return out;
}

export const normalWeb3 = new Web3(window.ethereum);