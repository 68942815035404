import {useWeb3React} from "@web3-react/core";
import {getWeb3, normalWeb3} from "../contract_utils/web3_functions";
import {InjectedConnector} from "@web3-react/injected-connector";
import {useEffect, useState} from "react";
import config from "../config";
import {useLocalStorage} from "./storage";


//METAMASK
export const MetamaskConnector = new InjectedConnector({
    supportedChainIds: [ 1, 3, 4, 5, 42, 11155111 ]
});

export const useWallet = (chainId: number, immediate: boolean, onError?: (e: any) => void) => {


    const handleErrors = (e: any) => {
        if(onError) {
            onError(e);
        } else {
            console.error(e);
        }
    }

    const web3React = useWeb3React();
    const [wasActive, setWasActive] = useLocalStorage("web3_was_active", false);
    const [active, setActive] = useState(web3React.active && (web3React.chainId === chainId));

    const activate = async () => {
        const web3Info = getWeb3();
        if(web3Info.success) {
            await web3React.activate(MetamaskConnector, onError, true);
            return true;
        }
        throw "no web3 client"
    }

    const change = async () => {
        if(normalWeb3) {
            // @ts-ignore
            const hex = normalWeb3.utils.toHex(chainId);
            await web3React.library.provider.request({
                method: "wallet_switchEthereumChain",
                params: [{ chainId: hex }]
            }).then(() => {
                setActive(true);
                setWasActive(true);
            }).catch((e: any) => {
                setActive(false);
                setWasActive(true);
                throw e.message
            })
        } else {
            throw new Error("no web3")
        }
    }

    useEffect(() => {
        if(immediate && !web3React.active) {
            // if web3 not active and immediate set
            activate().catch(handleErrors)
        } else if(wasActive) {
            activate().catch(handleErrors)
        }
    }, [])

    useEffect(() => {
        if(web3React.active) {
            if(web3React.chainId !== chainId) {
                change().catch(handleErrors)
            }
        }
    }, [web3React.active])

    useEffect(() => {
        setActive(web3React.chainId === chainId)
        setWasActive(web3React.chainId === chainId);
    }, [web3React.chainId])

    const deactivate = () => {
        web3React.deactivate();
        setActive(false);
        setWasActive(false);
    }

    return {active, connected: web3React.active, account: web3React.account, chainId: web3React.chainId, connector: web3React.connector, library: web3React.library, activate, deactivate, change, web3: normalWeb3}
}