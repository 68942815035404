
export function switchToClaimTitle() {
    let title = document.querySelector("h1.elementor-heading-title.elementor-size-default")
    if(title) {
        title.innerHTML = "Finalize your claim to receive One-of-a-Kind Mimotho Jewellery"
    }
    let desc = document.querySelector("div.elementor-text-editor.elementor-clearfix p")
    if(desc) {
        desc.innerHTML = "Welcome to Mimotho NFT! As a proud owner of an NFT purchased on OpenSea, you can now finalize your claim of Mimotho jewellery through the <br/> form below. Please fill out the form with your personal details, including your address, so we can deliver your new jewellery within 4 to 6 weeks: "
    }
}

export function switchToNormalTitle() {
    let title = document.querySelector("h1.elementor-heading-title.elementor-size-default")
    if(title) {
        title.innerHTML = "Where Luxury Meets Innovation."
    }
    let desc = document.querySelector("div.elementor-text-editor.elementor-clearfix p")
    if(desc) {
        desc.innerHTML = "Welcome to Mimotho NFTs, the captivating union of craftsmanship and cutting-edge technology. Claim a unique jewellery piece that effortlessly embodies your personal style while making an indelible digital declaration of ownership. The future is here, and it looks stunning on you!"
    }
}