
// Get ownership of nft.

import React, { useEffect, useRef, useState } from "react";

//import { SubmitHandler, useForm } from "react-hook-form";
import crypto from "crypto-js"

import { useWeb3React } from "@web3-react/core";
import { ABI } from "./contract_utils/contract_abi";
import Web3 from "web3";
import { ethers } from "ethers";

//import { sign } from "crypto";

import { toast } from 'react-toastify';
import config from "./config.js";
import {useWallet} from "./walletconnect/wallet";
import {getWeb3} from "./contract_utils/web3_functions";
import { switchToClaimTitle, switchToNormalTitle } from "./title";
// Alerts


let serverUrl = window.location.origin;

const defaultImage = require ("./img/unavailable.png");
const metamaskImg = require ("./img/metamaskDark.jpg");



const web3 = new Web3(window.ethereum);



let tokenAddress="0xbf704c92a0341015d44ef1c0569638b55a70afa7"
let nfts = [
    
    {
        "name": 'Balance Necklace, 14kt rose gold',
        "group":1,
        "tokenID":'48030468130249781770274421472986192088061940366981360181457336190796617527742',
        "picture":"",
        "nft_id": "0x6a304816175801b5d5dd67ce96eb1caacaf94a629707b41ed5dc4f5ab9c8b1be",
        "link":"https://opensea.io/assets/ethereum/0x83e9f98fe8f67645eb372bc92a6a8f99bf9be690/48030468130249781770274421472986192088061940366981360181457336190796617527742",
    },{
        "name": 'Balance Necklace, 14kt white gold',
        "group":1,
        "tokenID":'35666381051818719901682962590201086302384820259817861159622793476488270838948',
        "picture":"",
        "nft_id": "0x4eda74cfb3a0d715be6fe1908e2eab3a4a46b0f063aeff28ac517dad1c59fca4",
        "link":"https://opensea.io/assets/ethereum/0x83e9f98fe8f67645eb372bc92a6a8f99bf9be690/35666381051818719901682962590201086302384820259817861159622793476488270838948",
    },{
        "name": 'Balance Necklace, 925 sterling silver',
        "group":1,
        "tokenID":'34244763072600086541611465177703959897952642811092462608559637142098972374307',
        "picture":"",
        "nft_id": "0x4bb5d95a2d5e1b3d431cda8eb0b0095c3ae4b6464582755138b24280ee0df523",
        "link":"https://opensea.io/assets/ethereum/0x83e9f98fe8f67645eb372bc92a6a8f99bf9be690/34244763072600086541611465177703959897952642811092462608559637142098972374307",
    },
    {
        "name": 'Momentum Bracelet, 14kt rose gold',
        "group":2,
        "tokenID":'38670163446490910101617591918802699596072766279358061715486642284802866210937',
        "picture":"",
        "nft_id": "0x557e897109c2379df2bc65a4013b812453083da61fd3b977952fd1d82d30e879",
        "link":"https://opensea.io/assets/ethereum/0x83e9f98fe8f67645eb372bc92a6a8f99bf9be690/38670163446490910101617591918802699596072766279358061715486642284802866210937",
    },{
        "name": 'Momentum Bracelet, 14kt white gold',
        "group":2,
        "tokenID":'84316871462167325523211056803293831823268894910191095659516095425265557901560',
        "picture":"",
        "nft_id": "0xba69a8765787e8e3aa28e2e10068c6343618df7e651cad8522dfcebad29d10f8",
        "link":"https://opensea.io/assets/ethereum/0x83e9f98fe8f67645eb372bc92a6a8f99bf9be690/84316871462167325523211056803293831823268894910191095659516095425265557901560",
    },{
        "name": 'Momentum Bracelet, 925 sterling silver',
        "group":2,
        "tokenID":'85142651632408739902648522788634427743861252119426467120623471648570454034429',
        "picture":"",
        "nft_id": "0xbc3d08773529ce828f664d04cf8715f6996517634c72a4488e9eb8b77717c7fd",
        "link":"https://opensea.io/assets/ethereum/0x83e9f98fe8f67645eb372bc92a6a8f99bf9be690/85142651632408739902648522788634427743861252119426467120623471648570454034429",
    },
    
    {
        "name": 'Pillar of Power Necklace, 14kt rose gold',
        "group":3,
        "tokenID":'103145515074508613001942912578196443262698628100003417362640125269706977838872',
        "picture":"",
        "nft_id": "0xe40a4aed4d48fbb10418f02c48f1f15bc45a9c2abd74a0af593b5f84c2c80718",
        "link":"https://opensea.io/assets/ethereum/0x83e9f98fe8f67645eb372bc92a6a8f99bf9be690/103145515074508613001942912578196443262698628100003417362640125269706977838872",
    },{
        "name": 'Pillar of Power Necklace, 14kt white gold',
        "group":3,
        "tokenID":'58619012672214223801278867432765453416675729166034377931445620499616766293181',
        "picture":"",
        "nft_id": "0x81992f77828ff27a42103f4e4088cddc626a0f33b2b258417659e4982a52f4bd",
        "link":"https://opensea.io/assets/ethereum/0x83e9f98fe8f67645eb372bc92a6a8f99bf9be690/58619012672214223801278867432765453416675729166034377931445620499616766293181",
    },{
        "name": 'Pillar of Power Necklace, 925 sterling silver',
        "group":3,
        "tokenID":'51149671208876860718443803705683800990974083602646568753550656788411017883425',
        "picture":"",
        "nft_id": "0x7115b01e893acfbea6ee91dbd7e8bbc3fc0daf6f11d55f317c1e627918c86f21",
        "link":"https://opensea.io/assets/ethereum/0x83e9f98fe8f67645eb372bc92a6a8f99bf9be690/51149671208876860718443803705683800990974083602646568753550656788411017883425",
    },
    {
        "name": 'Stability Necklace, 14kt rose gold',
        "group":4,
        "tokenID":'71513418851075774430078996774556242467451675238145974422875185186438706551836',
        "picture":"",
        "nft_id": "0x9e1b292297492ff9c1a6e9262668240283acfb008d718c67407070bf4cdedc1c",
        "link":"https://opensea.io/assets/ethereum/0x83e9f98fe8f67645eb372bc92a6a8f99bf9be690/71513418851075774430078996774556242467451675238145974422875185186438706551836",
    },{
        "name": 'Stability Necklace, 14kt white gold',
        "group":4,
        "tokenID":'85637648178874857286027236658963430296090411665213104541154719218717883047393',
        "picture":"",
        "nft_id": "0xbd5530f18e0ca99c6f178dcab0bf71c90ac8d23bbc513763d7b73a3bdd831de1",
        "link":"https://opensea.io/assets/ethereum/0x83e9f98fe8f67645eb372bc92a6a8f99bf9be690/85637648178874857286027236658963430296090411665213104541154719218717883047393",
    },{
        "name": 'Stability Necklace, 925 sterling silver',
        "group":4,
        "tokenID":'66953567914367973321603292702477879895584954219583405977560731812671191296343',
        "picture":"",
        "nft_id": "0x940660632f35ef8e28575f3c25c7a12256d2c7175040f279cd0f1030dcdca957",
        "link":"https://opensea.io/assets/ethereum/0x83e9f98fe8f67645eb372bc92a6a8f99bf9be690/66953567914367973321603292702477879895584954219583405977560731812671191296343",
    }

   /* 
{
    "name": 'Abstract Art num. 1',
    "group":1,
    "tokenID":'63767820829301618589931853762872978411751290722348868932521816235898560336632',
    
    "link":"https://testnets.opensea.io/assets/goerli/0xbf704c92a0341015d44ef1c0569638b55a70afa7/63767820829301618589931853762872978411751290722348868932521816235898560336632",
},{
    "name": 'Abstract Art num. 2',
    "group":1,
    "tokenID":'113246753958308660783217592500597831806708065674439292565192125827165997576690',
    "picture":require("./img/nft-claim/claim2.jpg"),
    "link":"https://testnets.opensea.io/assets/goerli/0xbf704c92a0341015d44ef1c0569638b55a70afa7/113246753958308660783217592500597831806708065674439292565192125827165997576690",
},{
    "name": 'Abstract Art num. 3',
    "group":1,
    "tokenID":'106594275970785305851803043360418724877064524870371799750923949791555059993791',
    "picture":require("./img/nft-claim/claim3.jpg"),
    "link":"https://testnets.opensea.io/assets/goerli/0xbf704c92a0341015d44ef1c0569638b55a70afa7/106594275970785305851803043360418724877064524870371799750923949791555059993791",
},
{
    "name": 'Abstract Art num. 4',
    "group":2,
    "tokenID":'57996079777822187752405250803266448186689504792045080625496245005953301665468',
    "picture":require("./img/nft-claim/claim4.jpg"),
    "link":"https://testnets.opensea.io/assets/goerli/0xbf704c92a0341015d44ef1c0569638b55a70afa7/57996079777822187752405250803266448186689504792045080625496245005953301665468",
},{
    "name": 'Abstract Art num. 5',
    "group":2,
    "tokenID":'41323596388567906530824963520279250247602415374971604082511699857288730510598',
    "picture":require("./img/nft-claim/claim5.jpg"),
    "link":"https://testnets.opensea.io/assets/goerli/0xbf704c92a0341015d44ef1c0569638b55a70afa7/41323596388567906530824963520279250247602415374971604082511699857288730510598",
},{
    "name": 'Abstract Art num. 6',
    "group":2,
    "tokenID":'96499846408706529571166833924435486331794045676937571831462011732171644843389',
    "picture":require("./img/nft-claim/claim6.jpg"),
    "link":"https://testnets.opensea.io/assets/goerli/0xbf704c92a0341015d44ef1c0569638b55a70afa7/96499846408706529571166833924435486331794045676937571831462011732171644843389",
},

{
    "name": 'Abstract Art num. 1',
    "group":3,
    "tokenID":'63767820829301618589931853762872978411751290722348868932521816235898560336632',
    "picture":require("./img/nft-claim/claim1.jpg"),
    "link":"https://testnets.opensea.io/assets/goerli/0xbf704c92a0341015d44ef1c0569638b55a70afa7/63767820829301618589931853762872978411751290722348868932521816235898560336632",
},{
    "name": 'Abstract Art num. 2',
    "group":3,
    "tokenID":'113246753958308660783217592500597831806708065674439292565192125827165997576690',
    "picture":require("./img/nft-claim/claim2.jpg"),
    "link":"https://testnets.opensea.io/assets/goerli/0xbf704c92a0341015d44ef1c0569638b55a70afa7/113246753958308660783217592500597831806708065674439292565192125827165997576690",
},{
    "name": 'Abstract Art num. 3',
    "group":3,
    "tokenID":'106594275970785305851803043360418724877064524870371799750923949791555059993791',
    "picture":require("./img/nft-claim/claim3.jpg"),
    "link":"https://testnets.opensea.io/assets/goerli/0xbf704c92a0341015d44ef1c0569638b55a70afa7/106594275970785305851803043360418724877064524870371799750923949791555059993791",
},
{
    "name": 'Abstract Art num. 4',
    "group":4,
    "tokenID":'57996079777822187752405250803266448186689504792045080625496245005953301665468',
    "picture":require("./img/nft-claim/claim4.jpg"),
    "link":"https://testnets.opensea.io/assets/goerli/0xbf704c92a0341015d44ef1c0569638b55a70afa7/57996079777822187752405250803266448186689504792045080625496245005953301665468",
},{
    "name": 'Abstract Art num. 5',
    "group":4,
    "tokenID":'41323596388567906530824963520279250247602415374971604082511699857288730510598',
    "picture":require("./img/nft-claim/claim5.jpg"),
    "link":"https://testnets.opensea.io/assets/goerli/0xbf704c92a0341015d44ef1c0569638b55a70afa7/41323596388567906530824963520279250247602415374971604082511699857288730510598",
},{
    "name": 'Abstract Art num. 6',
    "group":4,
    "tokenID":'96499846408706529571166833924435486331794045676937571831462011732171644843389',
    "picture":require("./img/nft-claim/claim6.jpg"),
    "link":"https://testnets.opensea.io/assets/goerli/0xbf704c92a0341015d44ef1c0569638b55a70afa7/96499846408706529571166833924435486331794045676937571831462011732171644843389",
}*/
];

// Form elements to timestamp
const initialValues = {
    name: "",
    address:"",
    post:"",
    email:"",
    ReqType:"saveReq",
    tokenId:"",
    itemName:"",
    claimWallet:"",

}

function App() {

    const notify = (err:any) => toast.error(err,{theme:"colored"});
    const notifySuccess = (text:any) => toast.success(text,{theme:"colored"});

    const {web3, active, connected, account, library, activate, change, deactivate, chainId} = useWallet(config.SmartContract_CHANNEL, false, (e: any) => {
        notify(e);
    });

    const [result, setResult] = useState('');
    const [walletui, setWalletui] = useState(false);
    const [nftList, setNftList] = useState<any[]>(nfts);
    const [showForm, setShowForm] = useState(false);
    const [claimItem, setClaimItem] = useState({"name":"","picture":"","tokenID":""});

    const [values, setValues] = useState(initialValues);

    useEffect(() => {

        const fn = () => {
            // trigger on click for connectWallet
            let web3Info = getWeb3();
            if(!web3Info.success) {
                let element = document.getElementById("overlay");
                if(element !== null){
                    console.log("inside here");
                    element.style.display = 'block';
                }
            } else {
                if(!active && connected) {
                    change().then(() => {
                        notifySuccess("Wallet connected")
                    }).catch(notify)
                } else {
                    if(connected) {
                        deactivate();
                    } else {
                        activate().catch(notify);
                    }
                }
            }
        }
        let elM = document.getElementById("mobile-menu-item-3863");
        if(elM) {
            elM.onclick = fn;
            let aEl = document.querySelector("#" + elM.id + " a")
            if(aEl) {
                if(active) {
                    aEl.innerHTML = "DISCONNECT WALLET"
                } else if(connected) {
                    aEl.innerHTML = "INCORRECT CHAIN"
                } else {
                    aEl.innerHTML = "CONNECT WALLET"
                }
                aEl.removeAttribute("href");
            }
        }
        let elX = document.getElementById("menu-item-3863");
        if(elX) {
            elX.onclick = fn;
            let aEl = document.querySelector("#" + elX.id + " a")
            if(aEl) {
                if(active) {
                    aEl.innerHTML = "DISCONNECT WALLET"
                } else if(connected) {
                    aEl.innerHTML = "INCORRECT CHAIN"
                } else {
                    aEl.innerHTML = "CONNECT WALLET"
                }
                aEl.removeAttribute("href");
            }
        }
    }, [active, connected])

    useEffect(() => {
        // get correct pictures
        Promise.all(nftList.map((nft) => {
            if(!nft.nft_id) {
                return nft.picture as string;
            } else {
                return fetch(config.website_link+'/getNft',{
                    method:"POST",
                    headers: {
                        accept: 'application.json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        "nft_id": nft.nft_id,
                        "req_type": "get_verify"
                    })
                }).then(resp => resp.json()).then(resp => {
                    let fileName: string = resp["details"]["file_name"];
                    if(fileName.indexOf("image") !== -1) {
                        return "data:" + fileName + ";base64," + resp["details"]["file"];
                    }

                    let fileNameSN: string = resp["details"]["thumbnail_img"];
                    if(fileNameSN.indexOf("image") !== -1) {
                        return fileNameSN;
                    }
                    return "No picture picture"
                })
            }
        })).then((pictures) => {
            setNftList(nftList.map((nft: any, index: number) => {
                return {...nft, picture: pictures[index]};
            }))
        })
    }, [])

    const handleInputChange = (e:any) => {
        const { name, value } = e.target;

        //Desctructing to get values
        setValues({
          ...values,
          [name]: value,
        });

    };

    useEffect(() => {
        setNftList(nftList.map((nft: any, index: number) => {
            return {...nft};
        }))
    }, [])

    useEffect(() => {
        if (connected) {
            if( !active ){
                notify( "Your wallet is not on "+config.SmartContract_ChannelName+" chain.");
            }
        }
        if(active) {
            // check all nft owners
            Promise.all(checkNft(nftList)).then(values => {
                Promise.all(nftList.map((nft: any) => {
                    const requestOptions: RequestInit = {
                        method: 'POST',
                        body: JSON.stringify({
                            tokenId: nft.tokenID,
                            ReqType:"checkReq",
                        })
                    };

                    return fetch(serverUrl  + '/api/v1/claimReq', requestOptions).then(resp => resp.json()).then(resp => {
                        if(resp.success) {
                            return resp.result;
                        }
                        throw resp.error;
                    })
                })).then(claimedValues => {
                    setNftList(nftList.map((nft: any, index: number) => {
                        return {...nft, isOwner: values[index], claimed: claimedValues[index]};
                    }))
                })
            }).catch((e) => {
                if(e.data?.originalError) {
                    notify(e.data.originalError.message);
                } else {
                    notify(e.message);
                }
            })
        }
    }, [active])

    const checkNft = (nfts: any): Promise<string>[] => {
        // check for ownership

        const smartContract = new web3.eth.Contract(ABI,  config.SmartContract_ADDRESS);
        return nfts.map(async (nft: any) => {

            let _nftId: any = web3.eth.abi.encodeParameter('uint256', nft.tokenID.toString());

            let res = await smartContract.methods.ownerOf(_nftId).call({ from: account }).catch((e: any) => {
                console.log(nft.tokenID);
                throw e;
            })

            console.log("Address", res);

            return res.toString() == account;
        })
        
    }

    const renderData = (group:any): JSX.Element[] => {
        let keyCounter = 0;
        const out = nftList.map((el) => {
            if(el.group === group){
                keyCounter ++;

                let onClick = () => {
                    if(!el.isOwner){
                        window.open( el.link, '_blank');
                    }
                    else{
                        setShowForm(true);
                        switchToClaimTitle();
                        setClaimItem(el);
                    }
                }

                let onClickWallet = () => {
                    let web3Info = getWeb3();
                    if(!web3Info.success) {
                        let element = document.getElementById("overlay");
                        if(element !== null){
                            console.log("inside here");
                            element.style.display = 'block';
                        }
                    } else {
                        if(!active && connected) {
                            change().then(() => {
                                notifySuccess("Wallet connected")
                            }).catch(notify)
                        } else {
                            if(connected) {
                                deactivate();
                            } else {
                                activate().catch(notify);
                            }
                        }
                    }
                }
    
                let onClickImg = () => {
                    window.open( el.link, '_blank');
                }
    
                return(
                    <div className="nft__card" key={el.tokenID + "_ " + keyCounter}>
                        <div className="nft_info"> 
                            <img src={(el.picture) ? el.picture: defaultImage}  onClick={onClickImg}></img>
                            <p className="text-primary-p">{el.name}</p>
    
                            <div style={{textAlign: "center"}}>
                                {el.claimed ? 
                                    <button className="btn-primary btn-claim" disabled>Item already claimed</button>
                                    : 
                                    <>
                                    
                                    {active ? <>
                                        <button className="btn-primary btn-claim" onClick={onClick}>{(el.isOwner) ? 'Claim': 'Buy NFT'}</button>
                                    </>:<>
                                        <button className="btn-primary btn-claim" onClick={onClickWallet}>Connect wallet</button>
                                    </>}</>
                                    
                                }
    
                            </div>
                        </div>
                    </div>
                )
            }else{
                return (<></>)
            }
            
        })
        return out;
    }

    const handleSubmit = async (e?: React.FormEvent<HTMLFormElement>) =>{

        // CRITICAL CODE. DO NOT TOUCH
        if(e) {
            e.preventDefault();
        }

        let emailHolder = validateEmail(values.email);

        if(emailHolder){

            values.itemName = claimItem.name;
            values.tokenId = claimItem.tokenID;
            if(account !== undefined && account !== null){
                values.claimWallet = account;
            }
            
            
            const requestOptions: RequestInit = {
                method: 'POST',
                body: JSON.stringify(values)
            };

            console.log(values);
    
            fetch(serverUrl  + '/api/v1/claimReq', requestOptions).then(async response => {
    
                // check for error response
                if (!response.ok ) {
                    // get error message from body or default to response status
                    console.log("Error in response code")
                    const error = await response.text();
                    return Promise.reject(error);
    
                }else{
    
                    const data1 = await response.text();
                    const data = JSON.parse(data1);
                    
                    if(data.success) {        
                        try {
                            console.log(data1);
                            notifySuccess("Congratulations on claiming your one-of-a-kind Mimotho jewellery piece! We appreciate your support and patience, and look forward to seeing you wear your new bespoke jewellery soon!");
                            setShowForm(false); 
                            switchToNormalTitle();
                            setClaimItem({"name":"","picture":"","tokenID":""})

                            setNftList(nftList.map((nft: any, index: number) => {
                                if (claimItem.tokenID === nft.tokenID) {
                                    return {...nft, claimed: true};
                                }
                                return nft;
                            }))

                        } catch (error:any) {
                            throw new Error(error.toString());
                        }
                        
                    } else {
                        throw new Error(data1);
                    }
                }
            })
            .catch(error => {
                console.error("Error in API Issue: ", error);
                notify(error.toString());
    
            });
        }else{
            notify("Your email address is invalid. \nEnter valid email address.");
        }
    } 
    
    let onClose = () => {
        setWalletui(true);
        let element = document.getElementById("overlay");
        if(element !== null){
            console.log("inside here");
            element.style.display = 'none';
        }
        
    }

    let connectWallet = () => {
        console.log("in here ");
        window.open('https://metamask.io/download/', '_blank');
    }

    

    return (<>
       

        <section id="nft-details nft-claim" className="content-wrap">
                <div id='overlay'>
                    <div className='popup'>
                        <div id='close' onClick={() => onClose()}>&#10006;</div>
                        <br></br>
                        <h2>Connect your wallet</h2> 
                        <p>In order to buy nft, please connect your wallet.</p>
                        <br></br>
                        <button className="btn-primaryWallet btn-claim" onClick={() =>connectWallet()}><img src={metamaskImg}></img> Metamask</button>
                    </div>
                </div>

           
            {showForm ? <>
                <div className="nft__cards">

                    <form onSubmit={handleSubmit} className="form">

                        <div>
                            {claimItem.hasOwnProperty("name") ? <h2>Claim item {claimItem.name}</h2> : <></>} 
                            <img src={(claimItem.picture) ? claimItem.picture: defaultImage}  ></img>
                        </div>
                        <br></br>
                        <div>
                            <label><span className="requiredMark">*</span> Full name </label> 
                            <input required className="verify-form" type="text"  maxLength ={140} value={values.name}  onChange={handleInputChange} name="name" />
                        </div>

                        <div>
                            <label><span className="requiredMark">*</span> Full Address </label> 
                            <input required className="verify-form"  maxLength ={400} value={values.address}  onChange={handleInputChange} name="address" />
                        </div>

                        <div>
                            <label><span className="requiredMark">*</span> Post </label> 
                            <input required className="verify-form"  maxLength ={400} value={values.post}  onChange={handleInputChange} name="post" />
                        </div>

                        <div>
                            <label><span className="requiredMark">*</span> Email address</label> 
                            <input required className="verify-form"  maxLength ={400} value={values.email}  onChange={handleInputChange} name="email" />
                        </div>

                        
                        <button id="timestamp-nft" className="btn-primary" type={"submit"}>Claim item</button>
                        
                    </form>

                    <div style={{width:"100%"}}>
                        <button  id="claim-nft-back" className="" onClick={() => {setShowForm(false); switchToNormalTitle(); setClaimItem({"name":"","picture":"","tokenID":""})}}>Back to collection</button>
                    </div>

                </div>    
            </>:<>

                <div>
                    <h2 className="subheader mimotho-titles">How it works?</h2>
                    <p>By acquiring a Mimotho NFT, you gain the privilege of claiming a one-of-a-kind, handcrafted jewellery piece designed by Metka Mikuletič. This is not just luxury; this is the future of ownership that comes with an exclusive NFT.</p>
                
                    <h6 className="process_steps"><strong>Step 1:</strong> Buy a Mimotho NFT at <a href="https://opensea.io/" target="_blank">OpenSea</a></h6>
                    <h6 className="process_steps"><strong>Step 2:</strong> Return to nft.mimotho.com and connect your digital wallet.</h6>
                    <h6 className="process_steps"><strong>Step 3:</strong> Claim your unique luxury jewellery piece and provide the shipment address.</h6>
                    <h6 className="process_steps"><strong>Step 4:</strong> Await your exclusive Mimotho jewellery delivery.</h6>
                </div>
                <br></br>
                <div>
                <h1 className="elementor-heading-title elementor-size-default subheader2 mimotho-titles" >The core collection</h1>
                    <p>Merging timeless elegance with cutting-edge style, the CORE Collection epitomizes sophistication, creating a captivating statement for the forward-thinking modern man who seeks unrivalled design and innovation.
                    Choose from our stunning selection of silver, white, or rose gold designs, and let your signature style leave a lasting impression. Because owning a Mimotho NFT isn't just a status symbol – it's a statement.
                    </p>
                </div>
                <br></br>

                <div className="nft__cards">
                    <div key="1" className="item_section">
                        <div className="item_header">
                            <h2>Balance Necklace</h2>
                            <p>Harmonious design, always in sync with the wearer.</p>
                        </div>
                        <div className="item_pictures">{ renderData(1) }</div>
                    </div>
                    
                    <div key="2" className="item_section">
                        <div className="item_header">
                            <h2>Momentum Bracelet</h2>
                            <p>Two Core elements flowing together in perfect unity.</p>
                        </div>
                        <div className="item_pictures">{ renderData(2) }</div>
                    </div>
                    
                    <div key="3" className="item_section">
                        <div className="item_header">
                            <h2>Pillar of Power Necklace</h2>
                            <p>The architectural signature piece connecting ground and sky.</p>
                        </div>
                        <div className="item_pictures">{ renderData(3) }</div>
                    </div>
                    
                    <div key="4" className="item_section">
                        <div className="item_header">
                            <h2>Stability Necklace</h2>
                            <p>Simple yet complex, interlocking pyramids on a solid base.</p>
                        </div>
                        <div className="item_pictures">{ renderData(4) }</div> 
                    </div>
                
                </div> 

                <br></br> 
                <br></br>
                <div>
                    <h2 className="subheader mimotho-titles">About Artist</h2>
                    <p>Metka Mikuletič, the visionary behind the Mimotho brand, merges architectural inspiration with luxurious precious metals to craft unique and striking jewellery pieces. Her work captivates stylish and discerning clients with geometric foundations, multidimensional forms, and vibrant precious stones.</p>
                
                </div>
            </>}
                
        </section>
    </>)
}

export default App;

function validateEmail(email:string) {
  
    var regex = new RegExp("^[A-Za-z0-9_!#$%&'*+\/=?`{|}~^.-]+@[A-Za-z0-9.-]+$", );
    var rex = regex.test(email);
    return rex;
}
